/**
 * External dependencies
 */
import { registerPlugin } from '@wordpress/plugins';
import { __ } from '@wordpress/i18n';
import { Icon, column } from '@wordpress/icons';
const render = () => {};
// registerPlugin('ccc-ccc', {
// 	render,
// 	scope: 'woocommerce/cart-line-items-block',
// });
// registerPlugin('shipping-workshop', {
// 	render,
// 	scope: 'woocommerce-checkout',
// });

